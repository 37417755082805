import { template as template_ccbb2c30a04b4cc2b3e6190d1f27b684 } from "@ember/template-compiler";
const WelcomeHeader = template_ccbb2c30a04b4cc2b3e6190d1f27b684(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
