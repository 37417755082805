import { template as template_0068b2ca3aaf47d9b8f558cf19b8cb53 } from "@ember/template-compiler";
const FKControlMenuContainer = template_0068b2ca3aaf47d9b8f558cf19b8cb53(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
