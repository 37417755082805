import { template as template_8ee72cbaf9eb49df831532f277bafe21 } from "@ember/template-compiler";
const FKLabel = template_8ee72cbaf9eb49df831532f277bafe21(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
